import DocumentItem from './DocumentItem'
import HandWritingInputItem from './HandWritingInputItem'
import { default_color, DNEALIAN_PRINT, CLASSIC_CURSIVE } from '../helpers/documentHelpers'

const HANDWRITING_ITEM_VERSION = 2

export default class HandWritingItem extends DocumentItem {
    constructor() {
        super()

        this.subtitle = ''
        this.line_style = 'Trace'
        this.line_height = 30
        this.number_of_lines = 1
        this.guide_line_color = default_color
        this.cursive_style = 'print'
        this.partials = []
        this.hw_font_cursive = CLASSIC_CURSIVE
        this.hw_font_print = DNEALIAN_PRINT
        this.version = HANDWRITING_ITEM_VERSION
        this.items = [new HandWritingInputItem()]
        this.spacing = 0
        this.color_option = 'solid'
    }

    migrateItem() {
        this.partials = this.subtitle
            ? [
                  {
                      start: 0,
                      end: this.subtitle.replace(/(<([^>]+)>)/gi, '').length,
                  },
              ]
            : []
        this.hw_font_cursive = CLASSIC_CURSIVE
        this.hw_font_print = DNEALIAN_PRINT
        this.version = HANDWRITING_ITEM_VERSION
    }

    copySettingFrom(item) {
        switch (item.type) {
            case 'handwriting':
                this.line_style = item.data.line_style
                this.line_height = item.data.line_height
                this.number_of_lines = item.data.number_of_lines
                this.guide_line_color = item.data.guide_line_color
                break
        }
    }
}
