import {
    WORD_SCRAMBLE_TYPE_LINES,
    WORD_SCRAMBLE_TYPE_MULTIPLE,
    WORD_SCRAMBLE_TYPE_MULTIPLE_PER_TERM,
} from '../helpers/documentHelpers'
import DocumentItem from './DocumentItem'

export default class WordScrambleItem extends DocumentItem {
    constructor() {
        super()

        this.terms = ['']
        this.line_length = 124.8
        this.type = WORD_SCRAMBLE_TYPE_LINES
        this.line_position = 'after'
        this.shuffle_seed = 0
        this.include_wordbank = 0
        this.wordbank_id = undefined
        this.number_of_columns = 2
        this.order_numbers_by = 'column'
        this.text_case = 'lowercase'
        this.shuffle_seeds = [0]
        this.scramble_by_word = 1
    }

    copySettingFrom(item) {
        switch (item.type) {
            case 'word_scramble':
                this.include_wordbank = item.data.include_wordbank
                this.type = item.data.type
                this.line_length = item.data.line_length
                this.line_position = item.data.line_position
                this.shuffle_seed = item.data.shuffle_seed
                this.wordbank_id = item.data.wordbank_id
                this.scramble_by_word = item.data.scramble_by_word
                this.terms = ['']
                if ([WORD_SCRAMBLE_TYPE_MULTIPLE, WORD_SCRAMBLE_TYPE_MULTIPLE_PER_TERM].includes(item.data.type)) {
                    this.terms_choices = [
                        { term_index: 0, answer: '', correct: false },
                        { term_index: 0, answer: '', correct: false },
                    ]
                }
                break
            case 'fill_in_the_blank':
                this.include_wordbank = item.data.include_wordbank
                this.wordbank_id = item.data.wordbank_id
                break

            default:
                break
        }
    }
}
