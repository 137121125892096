import uuidv1 from 'uuid/v1'
import cloneDeep from 'lodash/cloneDeep'

export default class HandWritingInputItemModel {
    constructor() {
        this.id = `hwi_${uuidv1()}`
        this.subtitle = ''
        this.partials = []
        this.line_style = 'Trace'
    }

    static fromObject(obj) {
        let newItem = new this()
        const newItemClone = cloneDeep(obj)
        Object.keys(newItem).forEach((key) => {
            if (key === 'id') return
            if (key in newItemClone) {
                if (key === 'partials') {
                    newItem[key] = newItemClone[key]
                        ? Array.isArray(newItemClone[key])
                            ? newItemClone[key]
                            : Object.values(newItemClone[key])
                        : []
                } else {
                    newItem[key] = newItemClone[key]
                }
            }
        })
        return newItem
    }
}
