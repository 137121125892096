import { GoogleAnalyticsEvent, EngagementEvents, EngagementEventType } from './types'

export class GoogleAnalyticsService {
    /**
     * Build event parameters
     * @param eventType
     * @param message
     * @returns GoogleAnalyticsEvent
     */
    private buildEventParams(eventType: string, message?: string): GoogleAnalyticsEvent {
        return {
            event_category: this.isEngagementEvent(eventType) ? 'engagement' : 'ecommerce',
            event_label: message,
        }
    }

    /**
     * Check if event type is an engagement event
     * @param eventType
     * @returns boolean
     */
    private isEngagementEvent(eventType: string): eventType is EngagementEventType {
        return EngagementEvents.includes(eventType as EngagementEventType)
    }

    /**
     * Log an event to Google Analytics
     * @param eventType
     * @param message
     * @returns void
     */
    public logEvent(eventType: string, message?: string) {
        try {
            const eventParams = this.buildEventParams(eventType, message)
            gtag('event', eventType, eventParams)
        } catch (error) {
            console.error('Failed to send Google Analytics event:', error)
        }
    }
}
