import { AnalyticsAdapter } from '../../AnalyticsService'
import { GoogleAnalyticsService } from './GoogleAnalyticsService'
import { shouldSendToGoogle } from './helpers'

export class GoogleAnalyticsAdapter implements AnalyticsAdapter {
    private googleAnalyticsService: GoogleAnalyticsService

    constructor() {
        this.googleAnalyticsService = new GoogleAnalyticsService()
    }

    /**
     * Track an event
     * @param eventType
     * @param message
     * @returns void
     */
    public trackEvent(eventType: string, message?: string) {
        if (!shouldSendToGoogle(eventType)) {
            return
        }

        this.googleAnalyticsService.logEvent(eventType, message)
    }
}

export default GoogleAnalyticsAdapter
