import { Store } from 'vuex'
import { WindowWithStatsig, EventMetadata } from './types'
import { EventName } from '../../../../objects/UserEvent'

export class StatsigService {
    constructor(private store: Store<any>) {}

    /**
     * Check if Statsig is initialized
     * @returns boolean
     */
    private isInitialized(): boolean {
        const win = window as unknown as WindowWithStatsig
        return !!(win.browser_id && win.statsig)
    }

    /**
     * Build metadata for the event
     * @param payload
     * @returns EventMetadata
     */
    private buildMetadata(payload?: any): EventMetadata {
        return {
            ...(payload || {}),
            page: this.store?.getters['document/entityType'],
            abGroup: this.store?.getters['abtests/abGroup'],
            url: window.location.href,
            referrer: document.referrer,
            isNewBrowser: (window as unknown as WindowWithStatsig).new_browser,
            isAuthenticatedUser: this.store?.getters['user/isLoggedIn'],
        }
    }

    /**
     * Get default message for the event
     * @returns string | undefined
     */
    private getDefaultMessage(): string | undefined {
        return this.store?.getters['document/entityType']
    }

    /**
     * Log an event to Statsig
     * @param eventType
     * @param message
     * @param payload
     * @returns void
     */
    public logEvent(eventType: EventName, message?: string, payload?: any) {
        if (!this.isInitialized()) {
            console.warn('Statsig not initialized')
            return
        }

        const finalMessage = message || this.getDefaultMessage()
        const metadata = this.buildMetadata(payload)

        const win = window as unknown as WindowWithStatsig
        win.statsig.logEvent(eventType, finalMessage, metadata)
    }
}
