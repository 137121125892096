import { VueConstructor } from 'vue'
import { Store } from 'vuex'
import { ErrorService } from '../apis/services/ErrorService'
import { EventService } from '../apis/services/EventService'
import type { PostErrorRequestDTO } from '../apis/types/PostErrorRequestDTO'
import type { PostEventRequestDTO } from '../apis/types/PostEventRequestDTO'
import { AnalyticsService } from '../services/analytics/AnalyticsService'
import { GoogleAnalyticsAdapter } from '../services/analytics/providers/google/GoogleAnalyticsAdapter'
import { StatsigAdapter } from '../services/analytics/providers/statsig/StatsigAdapter'
import { EventDefinition, UserEvent, getEventProviders } from '../objects/UserEvent'

declare global {
    interface Window {
        browser_id: string
    }
}

/**
 * Extract error payload from error object. This is only used for logging Event errors.
 * @param error
 * @returns PostErrorRequestDTO
 */
const extractErrorPayload = (error: unknown): PostErrorRequestDTO => {
    const err = error as any
    return {
        url: window.location.pathname,
        message: err?.response?.data?.error ?? err?.message ?? 'No error message provided',
        line: err?.lineNumber ?? 0,
        column: err?.columnNumber ?? 0,
        stack: err?.stack ?? '',
    }
}

/**
 * Check if the event should be sent to internal service
 * @param eventType
 * @returns boolean
 */
const shouldSendToInternal = (eventType: string): boolean => {
    const providers = getEventProviders(eventType)
    return providers.includes('internal') || !Object.values(UserEvent).some((e) => e.name === eventType)
}

export const EventTracking = {
    install(Vue: VueConstructor, store: Store<any>) {
        const analyticsService: AnalyticsService = new AnalyticsService()
        analyticsService.addProvider(new GoogleAnalyticsAdapter())
        analyticsService.addProvider(new StatsigAdapter(store))

        Vue.prototype.$logTrackingEvent = async function (
            eventType: EventDefinition | string,
            message?: string,
            hasModification: boolean = false,
            useBeacon: boolean = false,
            extraData?: any,
        ) {
            if (!window.browser_id) return

            if (typeof eventType === 'object') {
                eventType = eventType.name
            }

            const payload: PostEventRequestDTO = {
                event_type: eventType,
                event_page: window.location.pathname,
                referrer: document.referrer,
                params: window.location.search,
                message: message ?? '',
                modified: hasModification,
            }

            try {
                if (shouldSendToInternal(eventType)) {
                    await EventService.postEvent({ payload, useBeacon })
                }

                analyticsService.trackEvent(eventType, message, extraData)
            } catch (error: unknown) {
                const errorPayload = extractErrorPayload(error)
                await ErrorService.postError({ payload: errorPayload })
                console.error('Failed to log event', error)
            }
        }
    },
}
