import { default_color, WORD_BANK_DEFAULT_COLORS } from '../helpers/documentHelpers'
import WordItem from './WordItem'

import uuidv1 from 'uuid/v1'

export default class WordbankItem {
    constructor() {
        this.id = uuidv1()
        this.words = []
        this.sort_order = 'a-z'
        this.border_width = 5
        this.border_style = 'double'
        this.text_case = 'capitalize'
        this.border_color = default_color
        this.color = WORD_BANK_DEFAULT_COLORS[0]
    }

    setWordbankColor(index, usedColors) {
        if (index >= 0 && index < 5) {
            const randomColor = Math.floor(Math.random() * 5)

            if (usedColors.includes(WORD_BANK_DEFAULT_COLORS[index])) {
                this.color = WORD_BANK_DEFAULT_COLORS[randomColor]
                return
            }

            this.color = WORD_BANK_DEFAULT_COLORS[index]
        } else {
            this.color = '#' + this.intToRGB(this.hashCode(this.id))
        }
    }

    hashCode(str) {
        var hash = 0
        for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash)
        }
        return hash
    }

    intToRGB(i) {
        var c = (i & 0x00ffffff).toString(16).toUpperCase()

        return '00000'.substring(0, 6 - c.length) + c
    }

    addWords(words, itemId) {
        words.forEach((word) => {
            const wordItem = new WordItem()
            wordItem.word = word
            wordItem.itemId = itemId
            this.words.push(wordItem)
        })
    }

    removeAll(itemId) {
        this.words = this.words.filter((word) => !word.itemId || (word.itemId && word.itemId !== itemId))
    }

    isEmpty() {
        return this.words.length === 0
    }

    removeOne(word, itemId) {
        let removeItems = []
        this.words.forEach((wordItem) => {
            if (wordItem.word === word && wordItem.itemId === itemId) {
                removeItems.push(wordItem)
            }
        })

        removeItems.forEach((ri) => this.words.splice(this.words.indexOf(ri), 1))
    }

    updateWord(oldWord, newWord, itemId) {
        this.words
            .filter((w) => w.itemId === itemId)
            .forEach((wi) => {
                if (wi.word === oldWord) {
                    wi.word = newWord
                }
            })
    }
}
