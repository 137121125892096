import apiClient from '../apiClient'
import type { PostErrorRequestDTO } from '../types/PostErrorRequestDTO'

export class ErrorService {
    /**
     * Post error response to the API
     * @param error
     * @returns response data object
     */
    public static postError = async ({ payload }: { payload: PostErrorRequestDTO }) => {
        try {
            await apiClient.post('/error', payload)
        } catch (error) {
            console.error('Unable to register captured error')
        }
    }
}
