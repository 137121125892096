import apiClient from '../../apis/apiClient'
import { dangerToast, successToast } from '../../helpers/toasts'

export const namespaced = true
export const state = {
    vue: null,
    grade_filters: [],
    activity_filters: [],
    subject_filters: [],
    rating_filter: 3,
    created_by_filters: [],
    is_loading: false,
    sort_by_options: [
        // {name: 'Most Relevant', value: 'relevance'},
        { name: 'Highest Rated', value: 'rating' },
        { name: 'Newest', value: 'latest' },
        // {name: 'Grade Level (Low > High)', value: 'grade-level-asc'},
        // {name: 'Grade Level (High > Low)', value: 'grade-level-desc'},
    ],
    created_by: [
        { name: 'Teachers', value: 'teacher' },
        { name: 'Homeschoolers', value: 'homeschoolers' },
        { name: 'Parents', value: 'parent' },
        { name: 'Others', value: 'other' },
    ],
    grades: [
        {
            name: 'Pre-K / Kindergaten',
            value: 'Pre-K K',
        },
        {
            name: '1st Grade',
            value: '1',
        },
        {
            name: '2nd Grade',
            value: '2',
        },
        {
            name: '3rd Grade',
            value: '3',
        },
        {
            name: '4th Grade',
            value: '4',
        },
        {
            name: '5th Grade',
            value: '5',
        },
        {
            name: '6th Grade',
            value: '6',
        },
        {
            name: '7th Grade',
            value: '7',
        },
        {
            name: '8th Grade',
            value: '8',
        },
        {
            name: '9th Grade',
            value: '9',
        },
        {
            name: '10th Grade',
            value: '10',
        },
        {
            name: '11th Grade',
            value: '11',
        },
        {
            name: '12th Grade',
            value: '12',
        },
        {
            name: 'College',
            value: 'College',
        },
        {
            name: 'Seniors',
            value: 'Adult 65+',
        },
        {
            name: 'Other',
            value: 'Other',
        },
    ],
    sort_by: 'rating',
    search_term: '',
    recent_searches: [],
    active_doc: null,
    unrated: true,
}

export const mutations = {
    SET_VALUE(state, values) {
        Object.keys(values).forEach((value) => {
            state[value] = values[value]
        })
    },
}

export const actions = {
    setValue({ commit }, value) {
        commit('SET_VALUE', value)
    },
    search({ commit, dispatch, getters, rootGetters }, searchTerm = null) {
        commit('SET_VALUE', { is_loading: true })
        let payload = {
            params: {
                grades: getters.gradeFilters,
                types: getters.activityFilters,
                subjects: getters.subjectFilters,
                rating: getters.ratingFilter ?? '',
                created_by: getters.createdByFilters,
                sort: getters.sortBy,
                query: searchTerm ?? getters.searchTerm,
                unrated: getters.unrated ?? true,
            },
        }

        if (!rootGetters['user/isLoggedIn'] && searchTerm) {
            dispatch('updateLocalRecentSearches', { storage: localStorage, searchTerm, payload })
            dispatch('updateLocalRecentSearches', { storage: sessionStorage, searchTerm, payload })
        } else if (rootGetters['user/isLoggedIn']) {
            sessionStorage.setItem('last_search', JSON.stringify(payload.params))
        }

        return apiClient.get('/search', payload)
    },
    loadMoreItems({ commit, dispatch, getters }, url) {
        // commit('SET_VALUE', {is_loading: true})
        let payload = {
            params: {
                grades: getters.gradeFilters,
                types: getters.activityFilters,
                subjects: getters.subjectFilters,
                rating: getters.ratingFilter ?? '',
                created_by: getters.createdByFilters,
                sort: getters.sortBy,
                query: getters.searchTerm,
                unrated: getters.unrated ?? true,
            },
        }
        return apiClient.get(url, payload)
    },
    getRecentSearches({ commit, dispatch, state, rootGetters }) {
        if (rootGetters['user/isLoggedIn']) {
            return apiClient
                .get('/search/recent')
                .then((response) => {
                    commit('SET_VALUE', response.data)
                })
                .catch((error) => {
                    state.vue?.$bvToast.toast('There was an error retrieving recent searches', dangerToast)
                })
        } else {
            let arr = JSON.parse(localStorage.getItem('recent_searches')) ?? []
            if (arr.length) {
                arr = arr.map((item, index) => {
                    return {
                        id: index,
                        payload: item,
                    }
                })
                commit('SET_VALUE', { recent_searches: arr })
            }
        }
    },
    updateLocalRecentSearches({ state }, { storage, searchTerm, payload }) {
        let arr = JSON.parse(storage.getItem('recent_searches')) ?? []
        let index = arr.findIndex((item) => item.query == searchTerm)
        if (index >= 0) {
            let params = { ...arr[index], ...payload.params }
            arr.splice(index, 1)
            arr.unshift(params)
        } else {
            arr.unshift(payload.params)
        }
        storage.setItem('recent_searches', JSON.stringify(arr))
    },
}

export const getters = {
    gradeFilters: (state) => state.grade_filters,
    subjectFilters: (state) => state.subject_filters,
    activityFilters: (state) => state.activity_filters,
    ratingFilter: (state) => state.rating_filter,
    createdByFilters: (state) => state.created_by_filters,
    sortByOptions: (state) => state.sort_by_options,
    createdBy: (state) => state.created_by,
    grades: (state) => state.grades,
    sortBy: (state) => state.sort_by,
    searchTerm: (state) => state.search_term,
    recentSearches: (state) => state.recent_searches,
    isLoading: (state) => state.is_loading,
    unrated: (state) => state.unrated,
    getFilters: (state, getters) => {
        return {
            query: getters.searchTerm,
            sort: getters.sortBy,
            grades: getters.gradeFilters,
            types: getters.activityFilters,
            subjects: getters.subjectFilters,
            rating: getters.ratingFilter ?? '',
            created_by: getters.createdByFilters,
            unrated: getters.unrated,
        }
    },
    activeDoc: (state) => state.active_doc,
}
