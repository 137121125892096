export interface GoogleAnalyticsEvent {
    event_category: 'ecommerce' | 'engagement'
    event_label?: string
}

export const EngagementEvents = Object.freeze([
    'leaving',
    'log-in-view-worksheet-rating',
    'login-worksheet-toolbar',
    'set_worksheet_type-bingo',
    'set_worksheet_type-fill_in_the_blank',
    'set_worksheet_type-flashcards',
    'set_worksheet_type-handwriting',
    'set_worksheet_type-matching',
    'set_worksheet_type-multiple_choice',
    'set_worksheet_type-word_scramble',
    'set_worksheet_type-open_response',
    'print',
    'sign-up-worksheet-toolbar',
] as const)

export type EngagementEventType = (typeof EngagementEvents)[number]
