import { Store } from 'vuex'
import { AnalyticsAdapter } from '../../AnalyticsService'
import { StatsigService } from './StatsigService'
import { shouldSendToStatsig } from './helpers'

export class StatsigAdapter implements AnalyticsAdapter {
    private statsigService: StatsigService

    constructor(store: Store<any>) {
        this.statsigService = new StatsigService(store)
    }

    /**
     * Track an event
     * @param eventType
     * @param message
     * @param extraData
     * @returns void
     */
    public trackEvent(eventType: string, message?: string, extraData?: any) {
        if (!shouldSendToStatsig(eventType)) {
            return
        }

        this.statsigService.logEvent(eventType, message, extraData)
    }
}

export default StatsigAdapter
