import Vue from 'vue'
import WebFont from 'webfontloader'
import {
    STANDARD_CURSIVE,
    STANDARD_PRINT,
    CLASSIC_PRINT,
    CLASSIC_CURSIVE,
    DNEALIAN_PRINT,
    HANDWRITING_TYPE_SOLID,
    HANDWRITING_TYPE_STROCK,
    HANDWRITING_TYPE_TRACE,
    HANDWRITING_TYPE_GUIDE,
    hwFontTransitions,
} from '../store/helpers/documentHelpers'

export const Fonts = new Vue({
    data: {
        ready: false,
        webFont: WebFont,
        requiredFonts: [],
        handwriting: [
            'Precursive Dashed',
            'Precursive Regular',
            'Precursive Stroked',
            'Cursive Dashed',
            'Cursive Regular',
            'Cursive Stroked',
            'Classic Print Dashed',
            'Classic Print Guided',
            'Classic Print Regular',
            'Classic Print Stroked',
            'Classic Cursive Dashed',
            'Classic Cursive Regular',
            'Classic Cursive Guided',
            'Classic Cursive Stroked',
            'D Nealian Print Dashed',
            'D Nealian Print Guided',
            'D Nealian Print Regular',
            'D Nealian Print Stroked',
        ],
        hwCursiveFonts: [
            CLASSIC_CURSIVE,
            STANDARD_CURSIVE,
            'Dancing Script',
            'Cookie',
            'Parisienne',
            'Imperial Script',
            'Pinyon Script',
            'Gwendolyn',
            'Mrs Saint Delafield',
            'Rochester',
            'Passions Conflict',
            'Dawning of a New Day',
            'Meddon',
            'League Script',
            'MonteCarlo',
        ],
        hwPrintFonts: [
            DNEALIAN_PRINT,
            CLASSIC_PRINT,
            STANDARD_PRINT,
            'Arial',
            'Comfortaa',
            'Comic Sans MS',
            'Coming Soon',
            'Delius',
            'Didact Gothic',
            'Indie Flower',
            'Montserrat',
            'Open Sans',
            'Oswald',
            'Play',
            'Poppins',
            'Questrial',
            'Roboto',
            'Shadows Into Light',
            'Tahoma',
            'Verdana',
        ],
        fonts: [
            'Arial',
            'Comic Sans MS',
            'Courier',
            'Georgia',
            'Lucida Typewriter',
            'Palatino',
            'Tahoma',
            'Times New Roman',
            'Trebuchet',
            'Verdana',
            'Catamaran',
            'Open Sans',
        ],
        googleFonts: [
            'Abril Fatface',
            'Amatic SC',
            'Anton',
            'Arvo',
            'Bitter',
            'Bree Serif',
            'Caveat',
            'Coming Soon',
            'Crimson Text',
            'Comfortaa',
            'Didact Gothic',
            'Delius',
            'Domine',
            'EB Garamond',
            'Gugi',
            'Indie Flower',
            'Josefin Slab',
            'League Script',
            'Lora',
            'Luckiest Guy',
            'Montserrat',
            'Noto Serif',
            'Orbitron',
            'Oswald',
            'Pacifico',
            'Permanent Marker',
            'Play',
            'Poppins',
            'Playfair Display',
            'Questrial',
            'Quicksand',
            'Roboto',
            'Rokkitt',
            'Sacramento',
            'Satisfy',
            'Schoolbell',
            'Shadows Into Light',
            'Special Elite',
            'Teko',
            'Zilla Slab',
        ],
        groupedHwCursiveFonts: [
            {
                group: 'Handwriting Fonts',
                list: [CLASSIC_CURSIVE, STANDARD_CURSIVE],
            },
            {
                group: 'Other Fonts',
                list: [
                    'Dancing Script',
                    'Cookie',
                    'Parisienne',
                    'Imperial Script',
                    'Pinyon Script',
                    'Gwendolyn',
                    'Mrs Saint Delafield',
                    'Rochester',
                    'Passions Conflict',
                    'Dawning of a New Day',
                    'Meddon',
                    'League Script',
                    'MonteCarlo',
                ],
            },
        ],
        groupedHwPrintFonts: [
            {
                group: 'Handwriting Fonts',
                list: [DNEALIAN_PRINT, CLASSIC_PRINT, STANDARD_PRINT],
            },
            {
                group: 'Other Fonts',
                list: [
                    'Arial',
                    'Comfortaa',
                    'Comic Sans MS',
                    'Coming Soon',
                    'Delius',
                    'Didact Gothic',
                    'Indie Flower',
                    'Montserrat',
                    'Open Sans',
                    'Oswald',
                    'Play',
                    'Poppins',
                    'Questrial',
                    'Roboto',
                    'Shadows Into Light',
                    'Tahoma',
                    'Verdana',
                ],
            },
        ],
    },
    created() {
        this.fonts = this.fonts.concat(this.googleFonts)
        this.fonts.sort()

        if (window.doc && !window.doc.is_published) {
            this.loadFonts()
            this.createFontCss()
        } else {
            this.loadDocumentFonts()
        }

        this.loadHWFonts()
    },
    methods: {
        getFontClass(font) {
            let fontStyle = ''
            if (this.isHwFont(font)) {
                fontStyle = 'font-weight-bold'
            }

            if (font) {
                return `${font.replace(/\s/g, '')} ${fontStyle}`
            } else {
                return false
            }
        },
        createDocumentFontsCSS(fontChoices) {
            // if the font css does not exist, create it.
            // this will generate our font style classnames
            if (!document.querySelector('style[id="font-families"]')) {
                if (!fontChoices) {
                    let fontChoices = this.fonts
                }

                let style = ''

                this.fonts.forEach((font) => {
                    style += '\n.' + this.getFontClass(font)
                    style += "{ font-family:'" + font + "'; }\n"
                })

                this.hwCursiveFonts.forEach((font) => {
                    style += '\n.' + this.getFontClass(font)
                    style += "{ font-family:'" + font + "'; }\n"
                })

                var css = document.createElement('style')
                css.type = 'text/css'
                if (css.styleSheet) {
                    css.styleSheet.cssText = style //firefox
                } else {
                    css.appendChild(document.createTextNode(style)) //everything else
                }
                css.setAttribute('id', 'font-families')
                document.getElementsByTagName('head')[0].appendChild(css)
            }
        },
        loadDocumentFonts() {
            if (!window.doc) return

            let fonts = [
                window.doc.title_font,
                window.doc.student_info_font,
                window.doc.instruction?.style?.font,
                window.doc.wordbank?.style?.font,
                window.doc.body?.font || window.doc.content?.font,
            ]
            let googleFontsToLoad = []
            for (let i = 0; i < fonts.length; i++) {
                if (this.googleFonts.includes(fonts[i]) && !googleFontsToLoad.includes(fonts[i])) {
                    googleFontsToLoad.push(fonts[i])
                }
            }
            if (googleFontsToLoad.length > 0) {
                this.webFont.load({
                    google: {
                        families: googleFontsToLoad,
                    },
                    active: () => {
                        this.ready = true
                    },
                })
            } else {
                this.ready = true
            }

            this.createDocumentFontsCSS(fonts)
        },
        loadFonts() {
            this.webFont.load({
                google: {
                    families: this.googleFonts,
                },
                active: () => {
                    this.ready = true
                },
            })
        },

        loadHWFonts() {
            this.webFont.load({
                google: {
                    families: this.hwCursiveFonts,
                },
                active: () => {
                    this.ready = true
                },
            })
        },

        createFontCss() {
            // if the font css does not exist, create it.
            // this will generate our font style classnames
            if (!document.querySelector('style[id="font-families"]')) {
                let fontChoices = this.fonts
                let style = ''

                this.fonts.forEach((font) => {
                    style += '\n.' + this.getFontClass(font)
                    style += "{ font-family:'" + font + "'; }\n"
                })

                this.hwCursiveFonts.forEach((font) => {
                    style += '\n.' + this.getFontClass(font)
                    style += "{ font-family:'" + font + "'; }\n"
                })

                var css = document.createElement('style')
                css.type = 'text/css'
                if (css.styleSheet) {
                    css.styleSheet.cssText = style //firefox
                } else {
                    css.appendChild(document.createTextNode(style)) //everything else
                }
                css.setAttribute('id', 'font-families')
                document.getElementsByTagName('head')[0].appendChild(css)
            }
        },
        isHwFont(font) {
            return this.isPrintHwFont(font) || this.isCursiveHwFont(font)
        },
        isPrintHwFont(font) {
            return [STANDARD_PRINT, CLASSIC_PRINT, DNEALIAN_PRINT].includes(font)
        },
        isCursiveHwFont(font) {
            return [STANDARD_CURSIVE, CLASSIC_CURSIVE].includes(font)
        },
        hasGuidedFont(font) {
            return [DNEALIAN_PRINT, CLASSIC_PRINT, CLASSIC_CURSIVE].includes(font)
        },
        getActiveHandwritingLineStyle(font, line_style) {
            if (!this.isHwFont(font)) {
                switch (line_style) {
                    case HANDWRITING_TYPE_GUIDE:
                    case HANDWRITING_TYPE_TRACE:
                        return HANDWRITING_TYPE_TRACE
                    case HANDWRITING_TYPE_SOLID:
                    case HANDWRITING_TYPE_STROCK:
                        return HANDWRITING_TYPE_SOLID
                    default:
                        return line_style
                }
            }

            if (!this.hasGuidedFont(font)) {
                if (line_style === HANDWRITING_TYPE_GUIDE) {
                    return HANDWRITING_TYPE_TRACE
                }
            }

            return line_style
        },
        getPlatformSpecificTransition(font) {
            const transitions = hwFontTransitions[font]
            if (!transitions) return '0px'

            const isWindows =
                navigator.userAgentData?.platform === 'Windows' || navigator.userAgent.toLowerCase().includes('win')
            return isWindows && transitions.windows ? transitions.windows : transitions.default
        },
    },
})
