import apiClient from '../apiClient'
import type { PostEventRequestDTO } from '../types/PostEventRequestDTO'

declare global {
    interface Window {
        app_url: string
    }
}

export class EventService {
    /**
     * Base URL for the API
     */
    private static baseUrl: string = window.app_url

    /**
     * Send a user event to the API
     * @param payload
     * @param useBeacon
     * @returns Promise<void>
     */
    public static postEvent = async ({ payload, useBeacon }: { payload: PostEventRequestDTO; useBeacon: boolean }) => {
        try {
            if (useBeacon && navigator.sendBeacon) {
                const data = this.dtoToBlob(payload)
                const boundSendBeacon = navigator.sendBeacon.bind(navigator)
                boundSendBeacon(`${this.baseUrl}/event`, data)
            } else {
                await apiClient.post('/event', payload)
            }
        } catch (error) {
            throw error
        }
    }

    /**
     * Convert PostEventRequestDTO to Blob
     * @param dto
     * @returns Blob
     */
    private static dtoToBlob = (dto: PostEventRequestDTO) => {
        const jsonData = JSON.stringify({
            event_type: dto.event_type,
            event_page: dto.event_page,
            referrer: dto.referrer,
            params: dto.params,
            message: dto.message,
            modified: dto.modified,
        })
        return new Blob([jsonData], { type: 'application/json' })
    }
}
