export interface AnalyticsAdapter {
    trackEvent: (eventType: string, message?: string, extraData?: any) => void
}

export class AnalyticsService {
    private providers: AnalyticsAdapter[] = []

    addProvider(provider: AnalyticsAdapter) {
        this.providers.push(provider)
    }

    trackEvent(eventType: string, message?: string, extraData?: any) {
        this.providers.forEach((provider) => {
            provider.trackEvent(eventType, message, extraData)
        })
    }
}
