import _ from 'lodash'
import { numberingFormats } from '../objects/NumberingFormat'

export const findObjectById = (id, list) => {
    let object = list.filter(function (ele) {
        return ele.id === id
    })

    if (Array.isArray(object)) {
        return object[0]
    }
    return object
}

function ObjectLength_Modern(object) {
    if (object) return Object.keys(object).length

    return 0
}

function ObjectLength_Legacy(object) {
    var length = 0
    for (var key in object) {
        if (object.hasOwnProperty(key)) {
            ++length
        }
    }
    return length
}

const objectLength = () => {
    return Object.keys ? ObjectLength_Modern : ObjectLength_Legacy
}

const isObject = (objValue) => {
    return objValue && typeof objValue === 'object' && objValue.constructor === Object
}

//Check if images array has uploaded images(Non Icon images)
export const hasNonIconImages = (list) => {
    let nonIcon = false
    if (_.isUndefined(list)) {
        return nonIcon
    }

    for (let i = 0; i < list.length; i++) {
        if (!list[i].is_icon) {
            nonIcon = true
            break
        }
    }

    return nonIcon
}

//Check if images array has uploaded images(Non Icon images)
export const hasIconImages = (list) => {
    let hasIcon = false
    if (_.isUndefined(list) || !list) {
        return hasIcon
    }

    for (let i = 0; i < list.length; i++) {
        if (list[i].is_icon) {
            hasIcon = true
            break
        }
    }

    return hasIcon
}

//Check if images array has uploaded images(Non Icon images)
export const iconImages = (list) => {
    if (_.isUndefined(list) || !list) {
        return []
    }
    let icons = []

    list.forEach((item) => {
        if (item.is_icon) {
            icons.push(item)
        }
    })

    return icons
}

export const getUndownloadedIcons = (list) => {
    if (_.isUndefined(list)) {
        return
    }
    let icons = []

    list.forEach((item) => {
        if (!item.downloaded) {
            icons.push(item)
        }
    })

    return icons
}

//Check if list of images array has premium Icon images)
export const hasPremiumIcon = (list) => {
    let premium = false
    if (_.isUndefined(list)) {
        return premium
    }

    list.forEach((item) => {
        if (item.is_icon && item.iconObject && (item.iconObject.is_premium || item.iconObject.our_premium)) {
            premium = true
        }
    })

    return premium
}

//Check if list of images array has premium Icon images)
export const freeIcons = (list) => {
    if (_.isUndefined(list)) {
        return []
    }

    return list.filter((item) => item.is_icon && !item.iconObject.is_premium)
}

export class IconCategoryObject {
    constructor(item) {
        this.identifier = item.identifier
        this.name = item.name
    }
}

export const hasPremiumNumbering = (value) => {
    if (!value) return false

    for (const format of numberingFormats) {
        // If the 'format' does not have subOptions, then it can access the 'isPremium' property directly.
        if (!Array.isArray(format.subOptions)) {
            return format.isPremium
        }

        for (const subOption of format.subOptions) {
            if (parseInt(subOption.value) === value) {
                return subOption.isPremium
            }
        }
    }
    return false
}

// Check if the document has a premium font/border color
export const hasPremiumColor = (document, default_color) => {
    const {
        worksheet: {
            body: { items, style },
        },
    } = document

    // Check document-level colors
    const documentColors = [document.title_color, document.student_info_color, style.color]

    if (documentColors.some((color) => color !== default_color)) {
        return true
    }

    // Color check rules for different item types
    const colorRules = {
        handwriting: (item) =>
            (item.data.color_option === 'solid' && item.data.guide_line_color !== default_color) ||
            item.data.color_option === 'red_blue',
        divider: (item) => item.data.line_color !== default_color,
        blank_space: (item) => item.data.box_outline && item.data.line_color !== default_color,
        section_header: (item) => item.data.color !== default_color,
        word_bank: (item) => item.data.border_color !== default_color,
        matching: (item) => item.style.border_color !== default_color,
        word_scramble: (item) => item.style.border_color !== default_color,
        instruction: (item) => item.style.border_color !== default_color,
    }

    return items.some((item) => {
        const rule = colorRules[item.type]
        return rule ? rule(item) : false
    })
}
